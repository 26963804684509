// ProtectedRoute.js
import { useEffect, useState } from 'react';
import { fetcher, getRole } from '../utils/helper';
import { getAllProfileDataByUser, getCustomerGeneralSettings, getLoginUserDetails, getSubItemDetails } from '../apiservice/ApiService';
import { Loader } from '../common/Loader';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ element, allowedRoles }) => {

  const [loading , setLoading] = useState(false);
  const navigate = useNavigate();

  const getRoleFromUrl = ()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const role = urlParams.get('role');
    
    return role;  // Return the role (or null if not found)
  }

  const getTokenFromUrl = ()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const token = urlParams.get('token');
    
    return token;  // Return the role (or null if not found)
  }

  const getCountryFromUrl = ()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const country = urlParams.get('country');
    
    return country;  // Return the role (or null if not found)
  }

  const getPathFromUrl = () =>{
      // Get the current URL
      const url = window.location.href;
          
      // Create a URLSearchParams object to work with query parameters
      const urlParams = new URLSearchParams(new URL(url).search);
      
      // Get the 'path' query parameter
      const path = urlParams.get('path');
      
      return path;  // Return the path (or null if not found)
  }

  const getBoardFromUrl = () =>{
        // Get the current URL
        const url = window.location.href;
          
        // Create a URLSearchParams object to work with query parameters
        const urlParams = new URLSearchParams(new URL(url).search);
        
        // Get the 'path' query parameter
        const board = urlParams.get('board');
        
        return board;  // Return the path (or null if not found)
  }

  const getIdFromUrl = () =>{
        // Get the current URL
        const url = window.location.href;
          
        // Create a URLSearchParams object to work with query parameters
        const urlParams = new URLSearchParams(new URL(url).search);
        
        // Get the 'path' query parameter
        const id = urlParams.get('id');
        
        return id;  // Return the path (or null if not found)
  }

  const role = getRoleFromUrl() || getRole();
  const token = getTokenFromUrl() || sessionStorage.getItem('token');
  const country = getCountryFromUrl() || sessionStorage.getItem('country');
  const path = getPathFromUrl();
  const boardId = getBoardFromUrl();
  const id = getIdFromUrl();


  const fetchApiData = async () => {
    try {
      setLoading(true);
      const response = await getCustomerGeneralSettings(role);
      const response1 = await getLoginUserDetails(token);

      if(response.success){
        sessionStorage.setItem(
          "settings",
          response.data.response.ui_settings
        );
        sessionStorage.setItem(
          "logo_location",
          response.data.response.logo_location
        );
      }
      if (response1.success) {
        sessionStorage.setItem("userEmail", response1.data.data.email);
        sessionStorage.setItem("userName", response1.data.data.name);
        sessionStorage.setItem("userId", response1.data.data.user_id);
        sessionStorage.setItem("phone", response1.data.data.phone);
        if(window.location.href.includes('role')){
          removeQueryParams();
          window.location.reload();
        }
      }

    } catch (err) {
     console.log(err , 'error');  // If an error occurs, store the error message
    } finally {
      setLoading(false);  // Always stop loading when done
    }
  };

  const getSubItemDetailsData = async (id) => {
    try {
      let url = `incorpify/getSubItemDetailsById/${id}`;
      let method = "GET";
      const response = await fetcher(url, method);
      return response;
    } catch (err) {
      console.log(err, "error");
    }
  };

  const getStatusText = (item, allColumns, columnIdData) => {
    let tempId = "";
    allColumns.forEach((subItem) => {
      if (subItem.id === columnIdData.required_columns.overall_status) {
        tempId = subItem.id;
      }
    });
    let value;
    item.column_values.forEach((subItem) => {
      if (subItem.id === tempId) {
        value = subItem.text;
      }
    });
    return value.toUpperCase();
  };

  const getStatusColor = (item, settingData) => {
    let tempBgColor = "#8080803b";
    settingData.statusColorSetting.forEach((details) => {
      const newArr = details.status.map((item) => item.trim().toLowerCase());
      if (newArr.includes(item.trim().toLowerCase())) {
        tempBgColor = details.color;
      }
    });
    return tempBgColor;
  };

  const getAllServiceData = (serviceData) => {
    let tempService = {};
    serviceData.forEach((item) => {
      if (item.board_id == boardId) {
        tempService = item;
      }
    });

    return tempService;
  };

  const filterSubItemDetailData = (subItemData) => {
    return subItemData.data.response.data.items[0];
  };

  const fetchColumnDetails = async () => {
    try {
      const response = await getSubItemDetails(id, boardId);

      return response.data.response.data.boards[0].columns;
    } catch (err) {
    } finally {
    }
  };

  const getProfileData = async () => {
    try {
      const response = await getAllProfileDataByUser();
      if (response.success) {
        sessionStorage.setItem("logo", response.data.response[0].file_location);
      }
      return response;
    } catch (err) {
      console.log(err , 'error');
    } 
  };

  const fetchApiDataForUserDetails = async () =>{


    try{

      setLoading(true);
      const response = await getCustomerGeneralSettings(role);
      const response1 = await getLoginUserDetails(token);

      if(response.success){
        sessionStorage.setItem(
          "settings",
          response.data.response.ui_settings
        );
        sessionStorage.setItem(
          "logo_location",
          response.data.response.logo_location
        );
      }
      if (response1.success) {
        sessionStorage.setItem("userEmail", response1.data.data.email);
        sessionStorage.setItem("userName", response1.data.data.name);
        sessionStorage.setItem("userId", response1.data.data.user_id);
        sessionStorage.setItem("phone", response1.data.data.phone);
        if(window.location.href.includes('role')){
          removeQueryParams();
          window.location.reload();
        }
      }
      let profileData = await getProfileData();
      let subItemDetailsResponse = await getSubItemDetailsData(id);
      let columnData = await fetchColumnDetails();
      let generalSettingData = await getCustomerGeneralSettings('customer');
  
      let allServiceData = getAllServiceData(
        profileData.data.response[0].services
      );
  
      let subItemDetailsData = filterSubItemDetailData(
        subItemDetailsResponse
      );
  
      const statusText = getStatusText(
        subItemDetailsData,
        columnData,
        JSON.parse(allServiceData.service_setting_data)
      );
  
      const statusColor = getStatusColor(
        statusText,
        JSON.parse(generalSettingData.data.response.ui_settings)
      );

        const dataToPass = {
          id: id,
          name: subItemDetailsData.name,
          status: statusText,
          color: statusColor,
          boardId: boardId,
          columnIdData: allServiceData.service_setting_data,
          subHeadingColumn: JSON.parse(allServiceData.service_setting_data)
            .sub_headings_column,
          service_setting_data: allServiceData.service_setting_data,
          service_column_value_filter:
            allServiceData.service_column_value_filter,
        };

        navigate("track/details", { state: dataToPass });

    }catch(err){
      console.log(err , 'error');
    }finally{
      setLoading(false);
    }
  }

  const removeQueryParams = () => {
    // Remove the query params from the URL (role and token)
    const url = new URL(window.location.href);
    url.searchParams.delete('role');
    url.searchParams.delete('token');
    url.searchParams.delete('country');

    // Update the URL without reloading the page
    window.history.pushState({}, '', url.toString());
  };

 useEffect(()=>{


  if(path){
    if(role !== null && token !== null && country !== null && boardId !== null){
      sessionStorage.setItem('token' , token);
      sessionStorage.setItem('role' , role);
      sessionStorage.setItem('country' , country);
      fetchApiDataForUserDetails();
    }
  }else{

    if(role !== null && token !== null && country !== null){
      sessionStorage.setItem('token' , token);
      sessionStorage.setItem('role' , role);
      sessionStorage.setItem('country' , country);
      fetchApiData();
    }

  }
  

  if(role === null){
    window.location.href = 'https://onboardify.tasc360.com';
  }

 } ,[role]);


 if (loading) {
  return <Loader />;
}

  return element;
};

